
          @use 'sass:math';
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























































































.did-you-know__inner {
  @extend %border-radius-small;

  padding: $spacing;
  background: $c-white;
  border: 1px solid $c-lighter-grey;
  box-shadow: $card-shadow;

  @include mq(l) {
    display: flex;
    align-items: center;
    padding: $spacing * 2;
  }
}

.did-you-know__svg {
  @extend %ff-alt;
  @extend %text-uppercase;

  display: block;
  width: 12rem;
  margin: auto;
  fill: currentColor;
  font-size: 1.8rem;
  letter-spacing: 0.05em;

  @include mq(l) {
    flex-shrink: 0;
    width: 21.6rem;
  }
}

.did-you-know__text {
  margin-top: $spacing * 1.5;

  @include mq(l) {
    margin-top: 0;
    margin-left: $spacing * 3;
  }
}
